// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-jsx": () => import("/Users/staffanadolfsson/Documents/sites/brosvets2019/src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-pages-404-jsx": () => import("/Users/staffanadolfsson/Documents/sites/brosvets2019/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-cisterner-tryckkarl-jsx": () => import("/Users/staffanadolfsson/Documents/sites/brosvets2019/src/pages/cisterner-tryckkarl.jsx" /* webpackChunkName: "component---src-pages-cisterner-tryckkarl-jsx" */),
  "component---src-pages-index-jsx": () => import("/Users/staffanadolfsson/Documents/sites/brosvets2019/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-industriunderhall-jsx": () => import("/Users/staffanadolfsson/Documents/sites/brosvets2019/src/pages/industriunderhall.jsx" /* webpackChunkName: "component---src-pages-industriunderhall-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("/Users/staffanadolfsson/Documents/sites/brosvets2019/src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-montagearbeten-jsx": () => import("/Users/staffanadolfsson/Documents/sites/brosvets2019/src/pages/montagearbeten.jsx" /* webpackChunkName: "component---src-pages-montagearbeten-jsx" */),
  "component---src-pages-om-oss-jsx": () => import("/Users/staffanadolfsson/Documents/sites/brosvets2019/src/pages/om-oss.jsx" /* webpackChunkName: "component---src-pages-om-oss-jsx" */),
  "component---src-pages-rorledningar-jsx": () => import("/Users/staffanadolfsson/Documents/sites/brosvets2019/src/pages/rorledningar.jsx" /* webpackChunkName: "component---src-pages-rorledningar-jsx" */),
  "component---src-pages-stalkonstruktioner-jsx": () => import("/Users/staffanadolfsson/Documents/sites/brosvets2019/src/pages/stalkonstruktioner.jsx" /* webpackChunkName: "component---src-pages-stalkonstruktioner-jsx" */),
  "component---src-pages-tjanster-jsx": () => import("/Users/staffanadolfsson/Documents/sites/brosvets2019/src/pages/tjanster.jsx" /* webpackChunkName: "component---src-pages-tjanster-jsx" */),
  "component---src-pages-vara-projekt-jsx": () => import("/Users/staffanadolfsson/Documents/sites/brosvets2019/src/pages/vara-projekt.jsx" /* webpackChunkName: "component---src-pages-vara-projekt-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/staffanadolfsson/Documents/sites/brosvets2019/.cache/data.json")

